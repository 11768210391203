
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Mortgage = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        {/* <!-- Start Main Top Header Area --> */}
      
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="main-banner-area" style={{marginTop:"80px"}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Mortgage Is A Great For You</h1>
                            <div class="banner-popup align-items-center">
                                <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        {/* <!-- End Main Banner Area -->
        
        <!-- Start About Style Area --> */}
        <div id="about" class="about-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                   
                    <div class="col-lg-12 text-center col-md-12">
                        <div class="about-style-content">
                            {/* <span class="sub-title">About Our Company</span> */}
                            <h3>Mortgage</h3>
                            <p class="bold">If you're looking for a mortgage, you may be wondering why you should choose Bandenia Challenger Finance over others.</p>
                            <p> Here are some reasons why you should consider choosing us for your mortgage needs:</p>

                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area -->

        <!-- Start Core Service Area --> */}
        
        {/* <!-- End Core Service Area -->

        <!-- Start Buying Home Area --> */}
        <div class="buying-home-area pb-100">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center">
                    

                    <div class="col-lg-12 col-md-12">
                        <div class="buying-home-content">
                            {/* <span class="sub-title text-center">Buying A Home</span> */}
                            <h3 class="text-center">We Are Here To Help You</h3>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Expertise</h4>
                                        <p>
                                            Our team has over 10 years of experience in the mortgage industry, and we have a deep understanding 
                                            of the different types of mortgages and how they work. We can provide expert advice to help you choose 
                                            the best mortgage product for your specific needs and financial situation.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Wide Range of Mortgage Products</h4>
                                        <p>We offer a wide range of mortgage products from various lenders, including fixed-rate mortgages, adjustable-rate mortgages and more. This means that we can help you find the best mortgage product for your specific requirements, whether you're a first-time homebuyer, looking to refinance, or seeking a mortgage for a higher-priced property.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Competitive Rates: </h4>
                                        <p>We work hard to secure competitive rates for our clients, ensuring that you get the best possible deal on your mortgage. We can help you compare rates and terms from different lenders to ensure that you get the most affordable option for your needs.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Personalized Service: </h4>
                                        <p>We believe in providing personalized service to our clients, taking the time to understand your specific needs and financial situation to recommend the best mortgage product for you. We are always available to answer your questions and provide ongoing support throughout the mortgage process.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Streamlined Application Process: </h4>
                                        <p>
                                            We understand that the mortgage application process can be overwhelming and time-consuming. That's why we have a streamlined application process that makes it easy for you to apply for a mortgage online or in-person. We guide you through the process, ensuring that you have all the necessary documents and information to complete your application successfully.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Trust and Transparency: </h4>
                                        <p>We believe in building trust with our clients by being transparent about our rates, fees, and services. We are committed to providing excellent service and ensuring that you have a positive experience working with us. By prioritizing our clients' needs and setting clear expectations, we build strong, long-lasting relationships with our clients.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Buying Home Area -->

        <!-- Start Mortgage Quote Area --> */}
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mortgage Quote Area -->

        <!-- Start Why Choose Us Area --> */}
        
        {/* {/* <!-- End Let’s Contact Area --> */}

        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Mortgage
