import React, { useState } from 'react'
import {
  Routes,
  Route
} from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { Fragment } from 'react'
import Home from './pages/Home'
import Calculate from './pages/Calculate'
import Mortgage from './pages/Mortgage'
import Homeloan from './pages/Homeloan'
import Compare from './pages/Compare'
import Personal from './pages/Personal'
import Loan from './pages/Loan'
import Balance from './pages/Balance'
import Commercial from './pages/Commercial'
import Warehouse from './pages/Warehouse'
import Resident from './pages/Resident'
import { Amplify } from 'aws-amplify'
import Sme from './pages/Sme'
import Team from './pages/Team'



function App() {

  // const [sidebarShow, setSidebarShow] = useState(false)
  return (
    <Fragment>
      {/* <Header /> */}
            <Routes>
              {/*<Route path='/' element={<React.Fragment><Home /></React.Fragment>} />*/}
              <Route path='/' element={<React.Fragment><Home /></React.Fragment>} />
               <Route path='/calculator' element={<React.Fragment><Calculate /></React.Fragment>} />
               <Route path='/mortgage' element={<React.Fragment><Mortgage /></React.Fragment>} />
               <Route path='/homeloan' element={<React.Fragment><Homeloan/></React.Fragment>} />
               <Route path='/compare' element={<React.Fragment><Compare/></React.Fragment>} />
               <Route path='/warehouse' element={<React.Fragment><Warehouse/></React.Fragment>} />
               <Route path='/commercial' element={<React.Fragment><Commercial/></React.Fragment>} />
               <Route path='/resident' element={<React.Fragment><Resident/></React.Fragment>} />
        <Route path='/personal' element={<React.Fragment><Personal /></React.Fragment>} />
        <Route path='/loan' element={<React.Fragment><Loan /></React.Fragment>} />
        <Route path='/balance' element={<React.Fragment><Balance /></React.Fragment>} />
               <Route path='/sme' element={<React.Fragment><Sme/></React.Fragment>} />
               <Route path='/our-team' element={<React.Fragment><Team/></React.Fragment>} />
             
       
            </Routes>
            {/* <Toast /> */}
    </Fragment>

  )
}

export default App
