
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Loan = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="confidence-home-area mt-5">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-content">   
                            <h1>Loan Against Property</h1>
                            <ul class="confidence-home-btn">
                                <li>
                                    <a href="/our-team" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-image">
                            <img src="./assets/images/confidence-home.png" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Main Banner Area --> */}
        <div class="why-choose-us-area-without-bg pt-1 pb-70">
            <div class="container">
                <div class="main-section-title">
                    
                    <h2>Loan Against Property</h2>
                    
                    <p>If you own a property, you can leverage its value to obtain a loan for various purposes, such as funding your business, renovating your home, paying for education, or consolidating debt. This type of loan is known as a loan against property (LAP) or mortgage loan. However, choosing the right lender or financial institution to get a loan against property can make a significant difference in terms of interest rates, fees, and overall experience. Here are some reasons why you should consider choosing Bandenia Challenger Finance for your loan against property needs:</p>
                </div>
                <div class="row justify-content-center">
                <Carousel  responsive={responsive} infinite={true} autoPlay={true}>
                
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-4.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Expertise</h3>
                                {/* <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Dealership to the next level</li>
                                    <li><i class='bx bx-check-circle'></i> Financing option for your dealership</li>
                                    <li><i class='bx bx-check-circle'></i> Business insight center</li>
                                </ul> */}
                                <p> 
                                Our team has over 10 years of experience in the loan against property industry. We can provide expert advice to help you choose the best loan against property product for your specific needs and financial situation. Our loan experts can guide you through the process, ensuring that you understand the terms and conditions of the loan and that you make informed decisions.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-5.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Competitive Interest Rates</h3>
                                <p>
                                We work hard to secure competitive interest rates for our clients, ensuring that you get the best possible deal on your loan against property. We can help you compare interest rates and terms from different lenders to ensure that you get the most affordable option for your needs. We also offer attractive interest rate discounts for existing customers or for applying online.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Personalized Service</h3>
                                <p>
                                 We believe in providing personalized service to our clients, taking the time to understand your specific needs and financial situation to recommend the best loan against property product for you. Our loan experts are always available to answer your questions and provide ongoing support throughout the loan process. We also provide doorstep service, so you don't have to leave the comfort of your home to apply for a loan against property.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Streamlined Application</h3>
                                <p>
                                 We understand that the loan application process can be overwhelming and time-consuming. That's why we have a streamlined application process that makes it easy for you to apply for a loan against property online or in-person. We guide you through the process, ensuring that you have all the necessary documents and information to complete your application successfully. We also offer fast loan processing and disbursement, so you can get the funds you need quickly.                                
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Trust and Transparency</h3>
                                <p>
                                We believe in building trust with our clients by being transparent about our interest rates, fees, and services. We are committed to providing excellent service and ensuring that you have a positive experience working with us. We also have a robust customer support system, so you can reach out to us anytime you need help or have a concern.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                </Carousel>
                </div>
            </div>
        </div>
        <div class="testimonials-style-area bg-ffffff pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="assets/images/testimonials-4.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content">
                            
                            <h3>Conclusion</h3>
                            <p>
                            In conclusion, choosing Bandenia Challenger Finance for your loan against property needs means working with a team of experts who can provide you with personalized service, a wide range of loan products, competitive interest rates, and a streamlined application process. We value your trust and are committed to helping you find the best possible loan against property product for your specific needs and financial situation. Contact us today to learn more about our loan against property products and services.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-gradient-shape">
                <img src="assets/images/shape.png" alt="image"/>
            </div>
        </div>

        {/* <!-- Start Mortgage Quote Area --> */} 
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Mortgage Quote Area --> */}

        
        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Loan
