import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Container,
  Row,
  Modal,
  Col,
  Form,
  InputGroup,
  Button,
  Card, 
  Spinner,
  Dropdown
} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Slider from 'react-custom-slider'
import './style/profile.css'
import Header from './Header'
// import { sidebar_check } from '../actions/sidebar'
// import { progress_value } from '../actions/progress'

const Calculator = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loanLength, setLoanLength] = useState(0)
  const [propertyPrice, setPropertyPrice] = useState(null)
  const [downPayment, setDownPayment] = useState(null)
  const [interest, setInterest] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const[sidebar, setSidebar]=useState(false)
    const showSidebar=()=>{
           setSidebar(true)   
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
  const handleGo = () => {
    navigate('/')
  }
  const r = (interest/100.0)/12.0
  const n = loanLength * 12
  const oneplusrn = Math.pow(1+r,n)
  const p = propertyPrice - downPayment
  console.log(r)
  console.log(n)
  console.log(p)
  // const monthlyPayment = ((propertyPrice - downPayment) * (interest * Math.pow(1 + parseInt(interest), loanLength * 12))) / (Math.pow(1 + parseInt(interest), loanLength * 12) - 1)    
  const monthlyPayment = (p*((r*oneplusrn)/(oneplusrn-1))).toFixed(2)
  return (
    <body class={`${sidebar ? 'overlay-body' : ''}`} style={{opacity: 0.9,}}>
       
{/* <div class="page-content header-bg">
    <div class="top-search">
        <div class="container">
            <div class="search-area">
                <Calculator/>
            </div>
        </div>
    </div>
   </div>  */}
   <Row>
    
    <Col fluid xs={12} lg={12} style={{ paddingBottom:'20px',display:'flex', flexDirection:'column', alignItems:'center' , marginBottom:'30px' , marginTop:'30px' }}>
    <div style={{
                maxWidth: '400px',
              fontSize: '12px'
              }}
            >
              <div style={{
                  margin: '1.4rem 0px 5px',
                fontSize: '14 px',
                fontWeight:'bold'
                }}
              >
                Property Price
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <InputGroup inline>
                <Form.Control
                  type='number'
                  className='input-box borderR-0'
                  value={propertyPrice}
                  onChange={e => setPropertyPrice(e.target.value)}
                  name='propertyPrice'
                />
                <InputGroup.Text
                  className='input-box borderL-0'
                  style={{ background: 'white', color: 'rgb(102, 102, 102)'}}
                >AED</InputGroup.Text>
              </InputGroup>
              <div style={{
                  margin: '1.4rem 0px 5px',
                  fontSize: '14px',
                  fontWeight:'bold', borderBlockColor: 'black'
                }}
              >
                Down Payment
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <div className='d-flex'>
                <InputGroup inline style={{
                    maxWidth: '100%',
                  }}
                >
                  <Form.Control
                    type='number'
                    className='input-box borderR-0'
                    value={downPayment}
                    onChange={e => setDownPayment(e.target.value)}
                    name='downPayment'
                  />
                  <InputGroup.Text
                    className='input-box borderL-0'
                    style={{background: 'white', color: 'rgb(102, 102, 102)'}}
                  >AED</InputGroup.Text>
                </InputGroup>
              
              </div>
              <div className='d-flex'>
              
                <InputGroup inline style={{
                    
                     marginTop:'10px'
                    // marginLeft: '13px'
                  }}
                >
                  {/* <Form.Control
                    type='number'
                    className='input-box borderR-0'
                  /> */}
                  { propertyPrice !== null ?  
                  <InputGroup.Text
                    className='input-box borderL-0'
                    style={{background: 'white', color: 'rgb(102, 102, 102)' , width:"100%" }}
                    disbaled
                  >{downPayment * 100 / propertyPrice}%</InputGroup.Text>
                  :
                  <InputGroup.Text
                  className='input-box borderL-0'
                  style={{background: 'white', color: 'rgb(102, 102, 102)' , width:"100%" }}
                  disbaled
                >0%</InputGroup.Text>
                }
                </InputGroup>
              </div>
              <div style={{
                  margin: '1.4rem 0px 5px',
                  fontSize: '14px',
                fontWeight:'bold'
                }}
              >
                Average interest rate
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'
                />
              </div>
              <InputGroup inline>
                <Form.Control
                  type='number'
                  className='input-box borderR-0'
                  value={interest}
                  onChange={e => setInterest(e.target.value)}
                  name='interest'
                />
                <InputGroup.Text
                  className='input-box borderL-0'
                  style={{
                    background: 'white',
                    maxWidth: '90px',
                  }}
                >%</InputGroup.Text>
              </InputGroup>
              <Form.Label style={{
                  marginTop: '20px',
                  fontSize: '11px',
                  color: 'rgb(102, 102, 102)',
                }}
              >This is just an estimated value based on the average of the market, your final interest rate depends on what you choose </Form.Label>
              <div style={{fontSize: '14px',
                fontWeight:'bold'}}>
                Length of loan
                <img
                  style={{width: '15px', marginLeft: '5px'}}
                  src='/assets/images/information-circle.72ccce5b.svg'/>
              </div>
              <Slider
                value={loanLength}
                trackLength={250}
                onChange={(value) => setLoanLength(value)}
                valueRenderer={(value) => `${value} years`}
                min={0}
                max={25}
                valueLabelStyle={{height:'0px', top:'30px !important', padding:'0px'}}
              />
            </div>
            <Form.Label style={{
                margin: '2.4rem 0px 0.4rem',
                display: 'block',
                fontSize: '14px',
                color: 'rgb(102, 102, 102)',
                }}
              >
                Estimated monthly payment
              </Form.Label>
              { propertyPrice !== null ? 
              <Form.Label style={{
                  fontSize: '48px',
                  lineHeight: '45px',
                  fontFamily: 'AGaramond',
                  letterSpacing: '-4px',
                  color: 'black',
                }}
              >
                 
                AED {monthlyPayment} </Form.Label>
                :
                <Form.Label style={{
                  fontSize: '48px',
                  lineHeight: '45px',
                  fontFamily: 'AGaramond',
                  letterSpacing: '-4px',
                  color: 'black',
                }}
              >
                 
                AED 0 </Form.Label>
}
                <br/>
              <Form.Label style={{
                  margin: '1rem 0px 0px',
                  fontSize: '14px',
                  color: 'rgb(102, 102, 102)',
                }}
              >This amount is based on a total <br/>
                <span style={{ color: 'black' , fontWeight:'bold'}}>&nbsp;loan of AED &nbsp;&nbsp; {propertyPrice - downPayment}</span>
              </Form.Label>
              <Form.Label style={{
                  fontSize: '14px',
                  lineHeight: '15px'
                }}
              > </Form.Label>
         
    </Col>
    

   </Row>      

</body>
      
    )
  }
  
  export default Calculator