
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Accordion
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Warehouse = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        {/* <!-- Start Main Top Header Area --> */}
       
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="main-banner-area" style={{marginTop:"80px"}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Warehouse And Building Finance</h1>
                            <div class="banner-popup align-items-center">
                                <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
       
        {/* <!-- End Main Banner Area -->*/}
        <div class="faq-style-area-with-full-width pb-100">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="faq-style-image">
                            <img src="/assets/images/image9.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="faq-style-accordion">
                            {/* <span class="sub-title">FAQ</span> */}
                            <h3 class="mb-2 mt-5">Need Help?</h3>
                            <p>If you're a business owner or an individual looking to purchase a warehouse or building in the UAE, you may be wondering how to finance the purchase. One option is to obtain a warehouse or building mortgage. In this blog, we'll discuss how we can help you with warehouse and building mortgages in the UAE</p>
                            <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>What is a warehouse and building mortgage?</Accordion.Header>
        <Accordion.Body class="mt-4 px-4 accord-p ">
        First, let's define what a warehouse or building mortgage is. A warehouse or building mortgage is a loan that is used to purchase a warehouse or building. We have special tie up with Banks and we can structure these loans considering your business income, current rentals and future projected rentals from purchase of these properties.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> How much I can afford to borrow?</Accordion.Header>
        <Accordion.Body class=" mt-4 px-4 accord-p ">
        We start by assessing your financial situation and determining how much you can afford to borrow. We'll then work with you to identify properties that meet your needs and budget. Once you've found a property you're interested in purchasing, we'll help you complete the mortgage application process.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header> Conclusion </Accordion.Header>
        <Accordion.Body class=" mt-4 px-4 accord-p ">
        In summary, if you're looking to purchase a warehouse or building in the UAE and need financing, we can help you obtain a warehouse or building mortgage. Our team of experts will guide you through the process, from assessing your financial situation to negotiating with lenders to securing the best possible mortgage terms. Contact us today to learn more about how we can help you with your warehouse or building mortgage needs.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
{/*  <!-- Start About Style Area --> */} 
     
        {/* {/* <!-- End Buying Home Area --> */}
        
        {/* <!-- Start Mortgage Quote Area --> */} 
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mortgage Quote Area -->

        <!-- Start Why Choose Us Area --> */}
       

        
<Footer/>
       
    </body>
        
    )
  }
  
  export default Warehouse
