import { useState } from "react";

import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const showSidebar = () => {
    setSidebar(true);
    localStorage.setItem("sidebar", sidebar);
  };
  const hideSidebar = () => {
    setSidebar(false);
    localStorage.setItem("sidebar", sidebar);
  };

  return (
    <div>
      {/* <!-- End Subscribe Style Area -->

        <!-- Footer Style Area --> */}
      <footer class="footer-style-area pt-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-6 col-lg-3">
              <div class="single-footer-widget">
                <div class="footer-logo">
                  <a href="https://www.facebook.com/bandenia" target="_blank">
                    <img
                      src="/assets/images/cropped-logo.png"
                      alt="Logo"
                      style={{ height: "80px", width: "90px" }}
                    />
                  </a>
                  <p>
                    {" "}
                    Our mortgage comparison will find the best mortgage deals
                    for you.
                  </p>
                  <ul class="footer-social">
                    <li>
                      <a
                        href="https://www.facebook.com/bandenia"
                        target="_blank"
                      >
                        <i class="bx bxl-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/bandenia_" target="_blank">
                        <i class="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/bandeniauae/mycompany/?viewAsMember=true"
                        target="_blank"
                      >
                        <i class="bx bxl-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bandenia_/"
                        target="_blank"
                      >
                        <i class="bx bxl-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-6">
              <div class="single-footer-widget ps-3">
                <h3>Quick Links</h3>
                <div className="row col-lg-12">
                  <div className="col-lg-6">
                    <ul class="quick-link">
                      <li>
                        <a href="/">Loan calculator </a>
                      </li>
                      <li>
                        <a href="https://broker.mortgagebcf.com/login">
                          Become a Partner
                        </a>
                      </li>
                      <li>
                        <a href="/mortgage">Home Loan</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <ul class="quick-link">
                      <li>
                        <a href="/balance">Balance transfer</a>
                      </li>
                      <li>
                        <a href="/commercial">Commercial loan </a>
                      </li>
                      <li>
                        <a href="/our-team">Mortgage Experts </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-lg-3">
              <div class="single-footer-widget">
                <h3>Address</h3>

                <ul class="address-info">
                  <li>
                    <i class="bx bx-phone-call"></i>
                    <a href="tel:+971 42014000">+971 42014000</a>
                  </li>
                  <li>
                    <i class="bx bx-message-square-detail"></i>
                    <a href="mailto:contact@bcf.ae">contact@bcf.ae</a>
                  </li>
                  <li>
                    <i class="bx bx-current-location"></i>
                    Conrad Dubai, 17th Floor, Office # 1707-08 Sheikh Zayed
                    Road, Dubai, U.A.E. Toll Free No: 800-Bandenia.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div style={{ left: "2rem" }}>
        <FloatingWhatsApp
          phoneNumber="+971551251768"
          accountName="Bandenia"
          chatMessage="Hello there! 🤝How we can help you?"
          allowEsc
          allowClickAway
          notification
          notificationSound
          avatar="./assets/images/cropped-logo.png"
          className="noextraheight"
        />
      </div>
      {/* <!-- End Footer Style Area -->*/}
    </div>
  );
};

export default Footer;
