import { useEffect, useState } from "react";

import $ from "jquery";
import { Accordion } from "react-bootstrap";
import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Calculator from "./Calculator";
import Footer from "./Footer";
import Header from "./Header";
//   import OwlCarousel from 'react-owl-carousel';

//   import 'owl.carousel/dist/assets/owl.carousel.css';

//   import 'owl.carousel/dist/assets/owl.theme.default.css';
//import $ from '../assets/js/bootstrap.bundle.min.js'

import "../assets/css/responsive.scss";
import Findloan from "./Findloan";
const Home = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const [navClass, setNavClass] = useState("");

  const handleScroll = () => {
    if (window.scrollY > 120) {
      setNavClass("is-sticky");
    } else {
      setNavClass("");
    }
  };

  const showSidebar = () => {
    setSidebar(true);
  };
  const hideSidebar = () => {
    setSidebar(false);
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
    },
    smallMobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    $(".loader").fadeOut(1500);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <body
      data-spy="scroll"
      data-offset="70"
      class={`${sidebar ? "overlay-body" : ""}`}
    >
      {/* <!-- Preloader --> */}
      <div class="loader">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
      {/* <!-- End Preloader --> */}

      {/* <!-- Start Main Navbar Area --> */}

      <Header />
      {/* <!-- End Main Navbar Area --> */}

      {/* <!-- Start Better Home Area --> */}

      <div id="home" class="better-home-area">
        <Findloan />
      </div>
      <div id="home">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12 text-center">
              <div class="better-home-content">
                <span class="sub-title">Welcome to Bandenia</span>
                <h1>Buy And Get Better Home</h1>
                <div className="row">
                  <div className="col-md-2"></div>
                  <div className="col-md-8">
                    <p>
                      BCF Platform is specifically designed with users, &
                      mortgage brokers in mind, it will make mortgage process
                      easier
                    </p>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <ul class="better-home-btn">
                  <li>
                    <a href="/compare" class="main-default-btn">
                      Compare the Mortgage
                    </a>
                  </li>
                  <li class="mt-2">
                    <a
                      href="https://user.mortgagebcf.com/"
                      class="main-optional-btn"
                    >
                      Get an instant quote
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="better-home-image">
                <Calculator />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Better Home Area --> */}

      {/* <!-- Start Information Area --> */}
      <div class="information-area pt-5 pb-5 mt-5 mb-5 bg-dark">
        <div class="container pt-5">
          <div class="row justify-content-center">
            <div class="col-lg-3 col-sm-6">
              <div class="information-card">
                <div class="icon">
                  <i class="bx bx-time"></i>
                </div>
                <p>
                  <span class="white">Mon-Fri (8am - 6pm)</span>
                  <span class="white">Sat-Sun (10am - 2pm)</span>
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="information-card">
                <div class="icon">
                  <i class="bx bxs-contact"></i>
                </div>
                <p>
                  <span class="white">Contact us</span>
                  <span class="white">
                    <a href="mailto:contact@bcf.ae">contact@bcf.ae</a>
                  </span>
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6">
              <div class="information-card">
                <div class="icon">
                  <i class="bx bx-phone-call"></i>
                </div>
                <p>
                  <span class="white">(24 hours / 7 days)</span>
                  <span class="white">
                    <a href="tel:+971 42014000">+971 42014000</a>
                  </span>
                </p>
              </div>
            </div>

            <div class="col-lg-3 col-sm-6" style={{ marginTop: "-20px" }}>
              <div class="information-card">
                <div class="icon">
                  <i class="bx bx-world"></i>
                </div>
                <p>
                  <span class="white">
                    Conrad Dubai, 17th Floor, Office # 1707-08 Sheikh Zayed
                    Road, Dubai, U.A.E. Toll Free No: 800-Bandenia.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Information Area --> */}

      {/* <!-- Start About Style Area --> */}
      <div id="about" class="about-style-area pb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="about-style-image-wrap">
                <img src="/assets/images/image2.png" alt="image" />

                {/* <div class="certified-image">
                                <img src="assets/img/more-home/about/certified.png" alt="image"/>
                            </div> */}
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="about-style-wrap-content">
                <span class="sub-title">About Our Company</span>
                <h3>We Are Fully Dedicated To Support You</h3>
                <p class="bold">
                  Easily Find the Mortgage Loan You're Looking For. Find the
                  Best Mortgage Loan. Find all the information you need in one
                  place.
                </p>

                <div class="about-list-tab">
                  <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="about-1-tab"
                        data-bs-toggle="tab"
                        href="#about-1"
                        role="tab"
                        aria-controls="about-1"
                      >
                        Our Mission
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content" id="myTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="about-1"
                      role="tabpanel"
                    >
                      <div class="content">
                        <ul class="list">
                          <li>
                            <i class="bx bx-chevrons-right"></i> All-in-one
                            Mortgage Platform
                          </li>
                          <li>
                            <i class="bx bx-chevrons-right"></i> A line of
                            credit for all your mortgage needs
                          </li>
                          <li>
                            <i class="bx bx-chevrons-right"></i> Our Mortgage
                            experts to guide you through the process
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="about-style-shape-1">
          <img src="/assets/images/image3.png" alt="image" />
        </div>
      </div>
      {/* <!-- End About/ */}

      {/* <!-- Start Mortgage Quote Area --> */}
      <div class="mortgage-quote-area-with-full-width">
        <div class="container-fluid">
          <div class="row m-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="mortgage-quote-content">
                <h3>Get Mortgage Quote</h3>
                <p>Compare the best mortgage rates across the whole market </p>
                <a href="https://user.mortgagebcf.com/" class="quote-btn">
                  Get Mortgage Quote
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="mortgage-quote-image"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Mortgage Quote Area --> */}

      {/* <!-- End Beyond Style Area --> */}

      {/* <!-- Start Fun Fact Style Area --> */}
      <div class="fun-fact-style-area pb-100" style={{ marginTop: "80px" }}>
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="row justify-content-center">
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="fun-fact-style-card">
                    <h3>20</h3>
                    <p>Years Of Experience</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="fun-fact-style-card bg-E5F9F4">
                    <h3>90</h3>
                    <p>Customer Worldwide</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="fun-fact-style-card bg-E5F9F4">
                    <h3>56</h3>
                    <p>Volunteers Participated</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="fun-fact-style-card">
                    <h3>98</h3>
                    <p>Veteran Home Completed</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="fun-fact-style-content">
                <span class="sub-title mb-0">Fact</span>
                <h3>
                  See what your interest rate and monthly payments could be, try
                  using our online mortgage rate finder
                </h3>
                <p class="bold">
                  • Fill in a few details about the mortgage you're looking for
                  and we'll show you the different types of mortgages you could
                  get with us
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="fun-fact-style-shape">
          <img src="/assets/img/more-home/fun-fact-shape.png" alt="image" />
        </div>
      </div>
      {/* <!-- End Fun Fact Style Area --> */}

      {/* <!-- Start Find Own Loan Area --> */}
      {/* <Findloan/> */}
      {/* <!-- End Find Own Loan Area --> */}

      {/* <!-- Start FAQ Style Area --> */}
      <div class="faq-style-area-with-full-width pb-100">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="faq-style-image">
                <img src="/assets/images/image9.png" alt="image" />
              </div>
            </div>

            <div class="col-lg-6 col-md-12">
              <div class="faq-style-accordion">
                <span class="sub-title">FAQ</span>
                <h3>Need Help? Read Popular Questions</h3>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      {" "}
                      How do you qualify for a loan ?{" "}
                    </Accordion.Header>
                    <Accordion.Body class="mt-4 px-4 accord-p ">
                      <p>
                        Qualifying for a loan depends on several factors,
                        including the lender's requirements, the type of loan
                        you are seeking, and your financial situation. However,
                        here are some general guidelines:
                        <ul>
                          <li>
                            <span class="faq-head"> Credit score: </span>
                            Your credit score is an important factor that
                            lenders consider when determining your eligibility
                            for a loan. A higher credit score indicates that you
                            have a good credit history, which makes you less of
                            a risk for the lender.
                          </li>
                          <li>
                            <span class="faq-head"> Income: </span>
                            Lenders want to make sure that you have enough
                            income to repay the loan. They will look at your
                            income and employment history to determine whether
                            you have a stable source of income.
                          </li>
                          <li>
                            <span class="faq-head"> Debt-to-income ratio:</span>{" "}
                            Lenders will also look at your debt-to-income ratio,
                            which is the amount of debt you have compared to
                            your income. If your debt-to-income ratio is too
                            high, it may be more difficult to qualify for a
                            loan.
                          </li>
                          <li>
                            <span class="faq-head"> Collateral: </span> Some
                            loans, such as secured loans, require collateral.
                            Collateral is an asset that you pledge as security
                            for the loan. If you default on the loan, the lender
                            can take possession of the collateral to recoup
                            their losses.
                          </li>
                          <li>
                            <span class="faq-head">Loan purpose: </span> Lenders
                            may also consider the purpose of the loan. For
                            example, if you are seeking a student loan, the
                            lender may look at your academic record to determine
                            whether you are likely to complete your degree and
                            find a job in your field.
                          </li>
                          <li>
                            <span class="faq-head"> Co-signer: </span> If you
                            have a poor credit history or low income, you may
                            need a co-signer to qualify for a loan. A co-signer
                            is someone who agrees to take responsibility for the
                            loan if you are unable to repay it.
                          </li>
                        </ul>
                        In summary, to qualify for a loan, you need to have a
                        good credit score, sufficient income to repay the loan,
                        a reasonable debt-to-income ratio, and collateral (if
                        required). Lenders may also consider the purpose of the
                        loan and whether you have a co-signer.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      {" "}
                      Can you get a mortgage without a credit score in UAE?{" "}
                    </Accordion.Header>
                    <Accordion.Body class=" mt-4 px-4 accord-p ">
                      <p>
                        In the UAE, it is possible to get a mortgage without a
                        credit score, but it can be challenging. Most lenders in
                        the UAE rely on credit scores to assess an individual's
                        creditworthiness and determine the interest rate for a
                        mortgage. However, some lenders may consider alternative
                        methods to evaluate creditworthiness, such as looking at
                        employment history, income stability, and rental payment
                        history.
                      </p>
                      <p>
                        Borrowers without a credit score may need to provide
                        additional documentation to demonstrate their ability to
                        repay the mortgage. This may include bank statements,
                        tax returns, and proof of employment. Borrowers may also
                        need to have a higher down payment or pay a higher
                        interest rate.
                      </p>
                      <p>
                        It's important to note that building a credit score is a
                        crucial step in establishing financial stability and
                        accessing affordable credit in the future. Borrowers
                        without a credit score in the UAE may want to consider
                        starting to build credit by applying for a secured
                        credit card or becoming an authorized user on a family
                        member's credit card. Additionally, working with a
                        mortgage broker who has experience working with clients
                        without credit scores may increase the chances of
                        securing a mortgage.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      {" "}
                      How mortgage advisor will help you?{" "}
                    </Accordion.Header>
                    <Accordion.Body class=" mt-4 px-4 accord-p ">
                      <p>
                        {" "}
                        A mortgage advisor is a professional who can provide
                        guidance and advice on obtaining a mortgage. They can
                        help you throughout the mortgage process, from finding
                        the right mortgage product to submitting your
                        application and closing the deal. Here are some ways
                        that a mortgage advisor can help you:
                      </p>
                      <br />
                      <h6>Provide Expertise:</h6>
                      <p>
                        A mortgage advisor has in-depth knowledge of the
                        mortgage market and can provide you with guidance on the
                        best products available to suit your financial situation
                        and goals. They can explain the different types of
                        mortgages and help you understand the terms and
                        conditions.
                        <ul>
                          <li>
                            <span class="faq-head">
                              {" "}
                              Offer Personalized Advice:{" "}
                            </span>{" "}
                            A mortgage advisor can provide tailored advice based
                            on your individual circumstances, such as your
                            income, credit score, and down payment amount. They
                            can help you determine the maximum mortgage amount
                            you can afford and advise you on how to improve your
                            chances of getting approved for a mortgage.
                          </li>
                          <li>
                            <span class="faq-head">
                              {" "}
                              Save You Time and Effort:{" "}
                            </span>{" "}
                            A mortgage advisor can help you complete the
                            mortgage application process efficiently and
                            accurately. They can also liaise with lenders on
                            your behalf and negotiate better rates and terms for
                            your mortgage.
                          </li>
                          <li>
                            <span class="faq-head">
                              {" "}
                              Access to Multiple Lenders:{" "}
                            </span>{" "}
                            A mortgage advisor has access to a wide range of
                            mortgage lenders, including traditional banks,
                            credit unions, and alternative lenders. This allows
                            them to find the best mortgage product for your
                            needs and help you save money.
                          </li>
                          <li>
                            <span class="faq-head">
                              {" "}
                              Provide Support Throughout the Process:
                            </span>{" "}
                            A mortgage advisor can guide you through the entire
                            mortgage process, including pre-approval, the
                            mortgage application, and closing the deal. They can
                            also answer any questions you have along the way and
                            help you understand the steps involved in buying a
                            home.
                          </li>
                        </ul>
                        Overall, working with a mortgage advisor can provide you
                        with the expertise, guidance, and support you need to
                        secure the best mortgage product for your financial
                        situation and goals.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      {" "}
                      Factors affecting your eligibility for mortgage in UAE.{" "}
                    </Accordion.Header>
                    <Accordion.Body class=" mt-4 px-4 accord-p ">
                      <p>
                        {" "}
                        Eligibility for a mortgage in the UAE refers to the
                        criteria and requirements that an individual must meet
                        in order to be considered for a home loan from a
                        financial institution in the United Arab Emirates. The
                        eligibility requirements may vary depending on the
                        lender, but generally, the following factors are taken
                        into consideration:
                        <ul>
                          <li>
                            <span class="faq-head"> Age: </span> The borrower
                            must be at least 21 years old, and in some cases, up
                            to 65 years old at the time of loan maturity.
                          </li>
                          <li>
                            <span class="faq-head"> Income: </span> The borrower
                            must have a stable and regular source of income that
                            can cover the mortgage repayments. Most lenders
                            require a minimum monthly salary of AED
                            10,000-15,000.
                          </li>
                          <li>
                            <span class="faq-head"> Employment: </span> The
                            borrower must have a stable job with a minimum of 6
                            months to 1 year of service in the UAE.
                          </li>
                          <li>
                            <span class="faq-head"> Credit history: </span> The
                            borrower's credit history and credit score will be
                            evaluated to determine their creditworthiness.
                          </li>
                          <li>
                            <span class="faq-head"> Down payment: </span> The
                            borrower must have a down payment of at least 20% of
                            the property value in most cases.
                          </li>
                          <li>
                            <span class="faq-head"> Property type: </span> The
                            type of property being purchased, such as a villa or
                            an apartment, may affect the borrower's eligibility
                            for a mortgage.
                          </li>
                          <li>
                            <span class="faq-head">
                              {" "}
                              Debt-to-income ratio:{" "}
                            </span>{" "}
                            The borrower's debt-to-income ratio will be
                            evaluated to determine their ability to make
                            mortgage payments while managing other debts.
                          </li>
                        </ul>
                        It is important to note that meeting these eligibility
                        criteria does not guarantee approval for a mortgage.
                        Lenders may also consider other factors such as the
                        borrower's employment stability, current debt levels,
                        and overall financial health.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      {" "}
                      Can I get a mortgage as a non-resident in UAE?{" "}
                    </Accordion.Header>
                    <Accordion.Body class=" mt-4 px-4 accord-p ">
                      <p>
                        {" "}
                        Yes, it is possible to obtain a mortgage as a
                        non-resident in the UAE. However, the process and
                        requirements may vary depending on the bank or lender.
                        Some of the common requirements may include a minimum
                        salary, a down payment, and a good credit history.
                        Non-residents may also need to provide additional
                        documentation such as proof of income and a valid
                        passport. It's recommended to research and compare
                        different lenders to find the best mortgage options for
                        non-residents in the UAE.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End FAQ Style Area -->

        <!-- Start Staff Area --> */}
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12 advisors">
            <h2> Our top mortgage advisors </h2>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "3" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/sabina.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Sabina</h4>
                <a
                  href="https://wa.me/+971542173349?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 54 217 3349{" "}
                  </h6>
                </a>
                <a href="mailto:Sabina@bcf.ae">
                  <h6>
                    <FiMail /> Sabina@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "2" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/ibrahim.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Ibrahim Shaikh </h4>
                <a
                  href="https://wa.me/+971565372034?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 56 537 2034{" "}
                  </h6>
                </a>
                <a href="mailto:Ibrahim@bcf.ae">
                  <h6>
                    <FiMail /> Ibrahim@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-3" style={{ order: "1" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/Wasim.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Wasim Shaikh </h4>
                <a
                  href="https://wa.me/+971564067781?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 56 406 7781{" "}
                  </h6>
                </a>
                <a href="mailto:wasim@bcf.ae">
                  <h6>
                    <FiMail /> wasim@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "4" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/kapil.jpg" alt="kapil" />
              </figure>
              <div class="green">
                <h4> Kapil Sewani </h4>
                <a
                  href="https://wa.me/+971524805639?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 52 480 5639{" "}
                  </h6>
                </a>
                <a href="mailto:Kapil@bcf.ae">
                  <h6>
                    <FiMail /> Kapil@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "5" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img
                  src="./assets/images/Abdul.jpg"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </figure>
              <div class="green">
                <h4> Abdul Khader Syed </h4>
                <a
                  href="https://wa.me/+971582496847?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 58 249 6847{" "}
                  </h6>
                </a>
                <a href="mailto:Abdul.khader@bcf.ae">
                  <h6>
                    <FiMail /> Abdul.khader@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "6" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/maheen.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Maheen Taimur </h4>
                <a
                  href="https://wa.me/+971557120434?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 55 712 0434{" "}
                  </h6>
                </a>
                <a href="mailto:maheen@bcf.ae">
                  <h6>
                    <FiMail /> maheen@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- End Staff Area --> */}

      {/* facilitators */}
      {/* <div className="container">
            <div className="row" style={{justifyContent:'center'}}>
            <div className="col-lg-12 text-center facilitator">
                <h3> Our Facilitators </h3>
                </div>
                <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                <div>
                        <img src="./assets/images/RAKBANK.png" />
                        <h6>RAK BANK</h6>
                    </div>
                    </div> 
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/sc.png" />
                        <h6>Standard Chartered Bank</h6>
                    </div>
                    </div> <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/emirates.png" />
                        <h6>Emirates Islamic</h6>
                    </div>
                    </div> <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/fab.png" />
                        <h6>First Abu Dhabi bank</h6>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/adcb.png" />
                        <h6>ADCB</h6>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/hsbc.svg" />
                        <h6>HSBC</h6>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/cbd.png" />
                        <h6>Commercial Bank of Dubai</h6>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/mashreq.svg" />
                        <h6>Mashreq</h6>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-5 col-sm-5 text-center brder">
                    <div>
                    <img src="./assets/images/adib.png" />
                        <h6>ADIB</h6>
                    </div>
                </div>
                
            </div>
        </div> 
     
        
        */}

      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center facilitator">
            <h3> Our Facilitators </h3>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="facilitator-grid">
            <div>
              <img src="./assets/images/dib.png" />
              <h6>Dubai Islamic Bank</h6>
            </div>
            <div>
              <img src="./assets/images/arab.svg" />
              <h6>Arab Bank</h6>
            </div>
            <div>
              <img src="./assets/images/cbd.png" />
              <h6>Commercial Bank of Dubai</h6>
            </div>
            <div>
              <img src="./assets/images/mashreq.svg" />
              <h6>Mashreq</h6>
            </div>
            <div>
              <img src="./assets/images/RAKBANK.png" />
              <h6>RAK BANK</h6>
            </div>
            <div>
              <img src="./assets/images/adcb.png" />
              <h6>ADCB</h6>
            </div>
            <div>
              <img src="./assets/images/sc.png" />
              <h6>Standard Chartered Bank</h6>
            </div>
            <div>
              <img src="./assets/images/hsbc.svg" />
              <h6>HSBC</h6>
            </div>
            <div>
              <img src="./assets/images/fab.png" />
              <h6>First Abu Dhabi bank</h6>
            </div>
            <div>
              <img src="./assets/images/emirates.png" />
              <h6>Emirates Islamic</h6>
            </div>
            <div>
              <img src="./assets/images/adib.png" />
              <h6>ADIB</h6>
            </div>
            <div>
              <img src="./assets/images/baroda.png" />
              <h6>Bank of Baroda</h6>
            </div>
          </div>
        </div>
      </div>

      {/* end facilitators */}

      {/* <!-- Start Let’s Contact Area --> */}

      <Footer />

      {/* <!-- dark version --> */}

      {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
      {/* <!-- dark version -->

        <!-- Essential JS --> */}
      <script src="../assets/js/jquery.min.js"></script>
      <script src="../assets/js/bootstrap.bundle.min.js"></script>
      {/* <!-- Magnific Popup JS --> */}
      <script src="../assets/js/jquery.magnific-popup.min.js"></script>
      {/* <!-- Owl Carousel JS --> */}
      {/* <script src="assets/js/owl.carousel.min.js"></script> */}
      {/* <!-- Nice Select JS --> */}
      <script src="../assets/js/jquery.nice-select.min.js"></script>
      {/* <!-- Form Ajaxchimp JS --> */}
      <script src="../assets/js/jquery.ajaxchimp.min.js"></script>
      {/* <!-- Form Validator JS --> */}
      <script src="../assets/js/form-validator.min.js"></script>
      {/* <!-- Contact JS --> */}
      <script src="../assets/js/contact-form-script.js"></script>
      {/* <!-- Mixitup JS --> */}
      <script src="../assets/js/jquery.mixitup.min.js"></script>
      {/* <!-- Custom JS --> */}
      <script src="../assets/js/custom.js"></script>
    </body>
  );
};

export default Home;
