
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Resident = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        {/* <!-- Start Main Top Header Area --> */}
       
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="main-banner-area" style={{marginTop:"80px"}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Non-Resident Mortgage</h1>
                            <div class="banner-popup align-items-center">
                                <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        {/* <!-- End Main Banner Area -->
        
        <!-- Start About Style Area --> */}
        <div id="about" class="about-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                   
                    <div class="col-lg-12 text-center col-md-12">
                        <div class="about-style-content">
                            <span class="sub-title">About Our Company</span>
                            <h3>Non-Resident Mortgage</h3>
                            <p class="bold">
                            Are you a non-resident looking to purchase a property in the United Arab Emirates (UAE)? Do you need assistance in securing a non-resident mortgage to finance your property purchase? Look no further than our expert team of professionals who specialize in non-resident mortgages in the UAE.
                            </p>

                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area -->

       
        <!-- Start Buying Home Area --> */}
        <div class="buying-home-area pb-100">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center">
                    

                    <div class="col-lg-12 col-md-12">
                        <div class="buying-home-content">
                            {/* <span class="sub-title text-center">Buying A Home</span> */}
                            <h3 class="text-center">We Are Here To Help You</h3>
                            
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        {/* <h4>Providing expert advice :</h4> */}
                                        <p>
                                        As a non-resident, obtaining a mortgage in the UAE can be a complex process. Our team of experienced mortgage advisors will work closely with you to navigate through the complexities of the UAE mortgage market and find the best non-resident mortgage solutions for your specific needs.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        {/* <h4>Access to a wide range of lenders: </h4> */}
                                        <p>Our first step is to gather your personal and financial information and assess your eligibility for a non-resident mortgage in the UAE. We will work with you to identify the best lenders and products that fit your unique financial situation.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        {/* <h4>Assistance with paperwork and documentation:  </h4> */}
                                        <p>Once we have identified the right mortgage solution for you, we will guide you through the application process and work with the lender to secure the best terms and rates. Our team will also help you with the documentation required for the mortgage application, such as income verification, proof of employment, and credit history.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        {/* <h4>Help with negotiations:  </h4> */}
                                        <p>In addition to providing mortgage solutions, we also offer a range of services to help non-residents with property purchases in the UAE. We can connect you with reputable real estate agents who can help you find your dream property, and provide legal advice on the legal and regulatory requirements for property purchases in the UAE.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        {/* <h4>Ongoing support:  </h4> */}
                                        <p>
                                        We understand that securing a non-resident mortgage in the UAE can be a daunting task, but our team is committed to making the process as smooth and stress-free as possible. We strive to provide our clients with excellent customer service, and we are dedicated to finding the best solutions for your specific needs.
                                        </p>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Buying Home Area --> */}
        <div class="testimonials-style-area bg-ffffff pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="assets/images/testimonials-4.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content">
                            
                            <h3>Conclusion</h3>
                            <p>
                            In conclusion, if you are a non-resident looking to purchase a property in the UAE and need assistance in securing a non-resident mortgage, our team of professionals is here to help. We have the expertise, knowledge, and experience to guide you through the process and find the best mortgage solutions for your needs. Contact us today to get started on your journey towards property ownership in the UAE.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-gradient-shape">
                <img src="assets/images/shape.png" alt="image"/>
            </div>
        </div>

        {/* <!-- Start Mortgage Quote Area --> */} 
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mortgage Quote Area -->

        <!-- Start Why Choose Us Area --> */}
       

        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Resident
