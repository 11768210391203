import { useEffect, useState } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    Accordion
    
  } from 'react-bootstrap'
  import { FloatingWhatsApp } from 'react-floating-whatsapp'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import { BsWhatsapp } from "react-icons/bs";
  import { FiMail } from "react-icons/fi";
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
import Findloan from './Findloan';
  const Calculate = () => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');

    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
   
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
       
     
    });
    

   
  

    return (
      <body data-spy="scroll" data-offset="70"  class={`${sidebar ? 'overlay-body' : ''}`}>
        
      {/* <!-- Preloader --> */}
      <div class="loader">
          <div class="d-table">
              <div class="d-table-cell">
                  <div class="spinner"></div>
              </div>
          </div>
      </div>
      {/* <!-- End Preloader --> */}

      {/* <!-- Start Main Navbar Area --> */}
      
      <Header/>
      {/* <!-- End Main Navbar Area --> */}

      {/* <!-- Start Better Home Area --> */}
     
     
      <div id="home"  class="better-home-area">
      
          <div class="container-fluid">
             

              <div class="row align-items-center">
                  <div class="col-lg-6 col-md-12 text-center">
                      <div class="better-home-content">
                          <span class="sub-title">Welcome to Bandenia</span>
                          <h1>Buy And Get Better Home</h1>
                          <div className="row">
                              <div className="col-md-2"></div>
                              <div className="col-md-8">
                          <p>BCF Platform is specifically designed with users, & mortgage brokers in mind, it will make mortgage process easier</p>
                          </div>
                          <div className="col-md-2"></div>
                          </div>
                          <ul class="better-home-btn">
                              <li>
                                  <a href="/compare" class="main-default-btn">Compare the Mortgage</a>
                              </li>
                              <li class="mt-2">
                                  <a href="https://user.mortgagebcf.com/" class="main-optional-btn">Get an instant quote</a>
                              </li>
                          </ul>
                      </div> 
                  </div>

                  <div class="col-lg-6 col-md-12">
                      <div class="better-home-image">
                         
                          <Calculator/>

                         
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- End Better Home Area --> */}
      
      {/* <!-- Start Information Area --> */}
      <div class="information-area pt-5 pb-5 mt-5 mb-5 bg-dark">
          <div class="container pt-5">
              <div class="row justify-content-center">
                  <div class="col-lg-3 col-sm-6">
                      <div class="information-card">
                          <div class="icon">
                              <i class='bx bx-time'></i>
                          </div>
                          <p>
                              <span class="white">Mon-Fri (8am - 6pm)</span>
                              <span class="white">Sat-Sun (10am - 2pm)</span>
                          </p>
                      </div>
                  </div>

                  <div class="col-lg-3 col-sm-6">
                      <div class="information-card">
                          <div class="icon">
                              <i class='bx bxs-contact'></i>
                          </div>
                          <p>
                              <span class="white">Contact us</span>
                              <span class="white"><a href="mailto:contact@bcf.ae">contact@bcf.ae</a></span>
                          </p>
                      </div>
                  </div>

                  <div class="col-lg-3 col-sm-6">
                      <div class="information-card">
                          <div class="icon">
                              <i class='bx bx-phone-call'></i>
                          </div>
                          <p>
                              <span class="white">(24 hours / 7 days)</span>
                              <span class="white"><a href="tel:+971 42014000">+971 42014000</a></span>
                          </p>
                      </div>
                  </div>

                  <div class="col-lg-3 col-sm-6" style={{marginTop:'-20px'}}>
                      <div class="information-card">
                          <div class="icon">
                              <i class='bx bx-world'></i>
                          </div>
                          <p>
                              <span class="white">Conrad Dubai, 17th Floor, Office # 1707-08 Sheikh Zayed Road, Dubai, U.A.E. Toll Free No: 800-Bandenia.</span>
                              
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- End Information Area --> */}

      {/* <!-- Start About Style Area --> */}
     
      {/* <!-- End About/ */}
      
      {/* <!-- Start Mortgage Quote Area --> */}
      <div class="mortgage-quote-area-with-full-width">
          <div class="container-fluid">
              <div class="row m-0">
                  <div class="col-lg-6 col-md-6 p-0">
                      <div class="mortgage-quote-content">
                          <h3>Get Mortgage Quote</h3>
                          <p>Compare the best mortgage rates across the whole market  </p>
                          <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6 p-0">
                      <div class="mortgage-quote-image"></div>
                  </div>
              </div>
          </div>
      </div>
      {/* <!-- End Mortgage Quote Area --> */}

     

    
      
     
<Footer/>


  </body>
      
    )
  }
  
  export default Calculate