
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Commercial = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="confidence-home-area mt-5">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-content">   
                            <h1>Commercial Mortgage</h1>
                            <ul class="confidence-home-btn">
                                <li>
                                    <a href="#" class="main-default-btn">Mortgage Expert</a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                                </li>
                            </ul>
                        </div> 
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="confidence-home-image">
                            <img src="./assets/images/confidence-home.png" alt="image"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Main Banner Area --> */}
        <div class="why-choose-us-area-without-bg pt-1 pb-70">
            <div class="container">
                <div class="main-section-title">
                    
                    <h2>Commercial Mortgage</h2>
                    
                    <p>If you are a business owner in the United Arab Emirates (UAE) and you're looking to expand your business or purchase a new commercial property, then you might need a commercial mortgage. However, obtaining a commercial mortgage can be a complex and time-consuming process. Fortunately, we can help you with your commercial mortgage needs in the UAE.</p>
                </div>
                <div class="row justify-content-center">
                <Carousel  responsive={responsive} infinite={true} autoPlay={true}>
                
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-4.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Expert advice and guidance</h3>
                                {/* <ul class="list">
                                    <li><i class='bx bx-check-circle'></i> Dealership to the next level</li>
                                    <li><i class='bx bx-check-circle'></i> Financing option for your dealership</li>
                                    <li><i class='bx bx-check-circle'></i> Business insight center</li>
                                </ul> */}
                                <p> 
                                We have a team of experienced mortgage advisors who can provide you with expert advice and guidance on the various options available to you. Our advisors can help you understand the different types of commercial mortgages, the criteria for eligibility, and the requirements for documentation.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-5.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Access to multiple lenders</h3>
                                <p>
                                We have tied up with all the leading Banks in UAE and can offer you the best solution as per your requirement. This means that we can provide you with access to a wide range of mortgage products and lenders. We can help you compare the various options and choose the one that best suits your needs and budget.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Streamlined application process</h3>
                                <p>
                                We understand that time is of the essence when it comes to commercial mortgages. That's why we have streamlined the application process to make it as simple and efficient as possible. We can help you with the application process from start to finish, ensuring that all the necessary documentation is in place and that the process runs smoothly.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Competitive rates</h3>
                                <p>
                                Our partnerships with multiple lenders mean that we can offer you competitive rates on your commercial mortgage. We can help you find the best rates and terms to ensure that your mortgage is affordable and fits your budget.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-11 col-md-11">
                        <div class="why-choose-us-item">
                            <div class="choose-image">
                                {/* <img src="assets/img/more-home/choose/choose-6.png" alt="image"/> */}
                            </div>
                            <div class="choose-content">
                                <h3>Ongoing support</h3>
                                <p>
                                Our support doesn't end once your mortgage is approved. We provide ongoing support to help you manage your mortgage and ensure that you meet your repayment obligations. We can help you with any questions or concerns you may have throughout the life of your mortgage.
                                </p>
                                
                            </div>
                        </div>
                    </div>

                </Carousel>
                </div>
            </div>
        </div>
        <div class="testimonials-style-area bg-ffffff pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="assets/images/testimonials-4.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content">
                            
                            <h3>Conclusion</h3>
                            <p>
                            In conclusion, obtaining a commercial mortgage in the UAE can be a daunting task, but we are here to help you every step of the way. Our expert advice, access to multiple lenders, streamlined application process, competitive rates, and ongoing support make us the ideal partner for your commercial mortgage needs. Contact us today to learn more about how we can help you with your commercial mortgage needs.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-gradient-shape">
                <img src="assets/images/shape.png" alt="image"/>
            </div>
        </div>

        {/* <!-- Start Mortgage Quote Area --> */} 
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Mortgage Quote Area --> */}

        
        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Commercial
