import { useState } from "react";

import { useNavigate } from "react-router-dom";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const showSidebar = () => {
    setSidebar(true);
    localStorage.setItem("sidebar", sidebar);
  };
  const hideSidebar = () => {
    setSidebar(false);
    localStorage.setItem("sidebar", sidebar);
  };

  return (
    // <body class={`${sidebar ? 'overlay-body' : ''}`}>

    // 	    <!-- Google Tag Manager (noscript) -->
    // <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKC66JC"
    // height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    // <!-- End Google Tag Manager (noscript) -->

    <div>
      <div
        className={`side-menu ${sidebar ? "show-menu" : "display"}`}
        id="sidebar-menu"
      >
        <img
          src="/assets/images/cropped-logo.png"
          alt=""
          style={{ height: "80px" }}
        />
        <br />
        <ul>
          <li>
            <a class="no-line" href="/">
              <span class="material-icons">
                <img
                  src="/assets/images/dashboard.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Home
            </a>
          </li>

          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/user-2.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              My Profile
            </a>
          </li>
          <li>
            <a class="no-line" href="/our-team">
              <span class="material-icons">
                <img
                  src="/assets/images/user-2.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Mortgage Experts
            </a>
          </li>
          {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/calculator.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Calculator
                    </a>
                </li> */}
          <li>
            <a class="no-line" href="/compare">
              <span class="material-icons">
                <img
                  src="/assets/images/status.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Compare Mortgage
            </a>
          </li>
          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/status.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Status
            </a>
          </li>

          <li>
            <a class="no-line" href="https://user.mortgagebcf.com/">
              <span class="material-icons">
                <img
                  src="/assets/images/setting.svg"
                  alt=""
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
              Profile Settings
            </a>
          </li>
          {/* <li>
                    <a class="no-line" href="https://user.mortgagebcf.com/">
                    	<span class="material-icons"><img src="/assets/images/share.svg" alt="" style={{height:'15px' , width:'15px'}} /></span>
						Social Media
                    </a>
                </li> */}
        </ul>
        <div class="close-btn" onClick={hideSidebar}>
          <span class="material-icons">
            <img
              src="/assets/images/close.svg"
              alt=""
              style={{ height: "24px", width: "24px" }}
            />
          </span>
        </div>
        <a
          class="sidebar-logout no-line main-default-btn2"
          style={{ height: "60px" }}
          href="https://user.mortgagebcf.com/"
        >
          <span>
            <img
              src="/assets/images/logout.svg"
              alt=""
              style={{ width: "24px" }}
            />
          </span>
          Sign Up as User
        </a>
        <a
          class="sidebar-logout no-line main-default-btn2"
          style={{ height: "60px", margin: "0px" }}
          href="https://broker.mortgagebcf.com/signup"
        >
          <span>
            <img
              src="/assets/images/logout.svg"
              alt=""
              style={{ width: "24px" }}
            />
          </span>
          Sign Up as Partner{" "}
        </a>
      </div>
      <div
        class="main-navbar"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <nav
          class="navbar navbar-style-two navbar-expand-lg navbar-light"
          style={{ backgroundColor: "white" }}
        >
          <div class="container">
            <div className="row col-sm-12">
              <div className="col-sm-12">
                <ul class="social-head" style={{ float: "right" }}>
                  <li>
                    <a
                      href="https://www.facebook.com/bandenia"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/bandenia_"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/bandeniauae/mycompany/?viewAsMember=true"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/bandenia_/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i class="bx bxl-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="logo col-lg-6">
                {/* <a href="#" class="link icon-only" onClick={showSidebar}>
                <i class="custom-hamburger">
                <GiHamburgerMenu/>
                    <span><b></b></span>
                </i>
            </a> */}
                <button
                  onClick={showSidebar}
                  class="navbar-toggler2"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-icon"></span>
                </button>
                <a href="/">
                  <img
                    src="/assets/images/cropped-logo.png"
                    className="white-logo"
                    alt="Logo"
                    style={{ height: "70px" }}
                  />
                </a>
              </div>

              <div
                class="collapse navbar-collapse col-lg-6"
                id="navbarSupportedContent"
              >
                <ul class="navbar-nav">
                  {/* <li class="nav-item">
                                <a href="#home" class="nav-link">
                                    Home
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#about" class="nav-link">
                                    About
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#services" class="nav-link">
                                    Our Services
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#team" class="nav-link">
                                    Team
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#blog" class="nav-link">
                                    Blog
                                </a>
                            </li>
                            <li class="nav-item">
                                <a href="#contact" class="nav-link">
                                    Contact
                                </a>
                            </li> */}
                </ul>

                <div class="others-option">
                  <div class="d-flex align-items-center">
                    <div class="option-item">
                      <a
                        href="tel:+971 42014000"
                        class="call-btn"
                        style={{ color: "black" }}
                      >
                        Call Us: +971 42014000
                      </a>
                    </div>
                    <div class="option-item">
                      <a href="https://user.mortgagebcf.com/" class="log-in">
                        Apply Now
                      </a>
                    </div>
                    <div class="option-item">
                      <a
                        href="https://broker.mortgagebcf.com/login"
                        class="sign-up"
                      >
                        Partner LogIn
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>

    // </body>
  );
};

export default Header;
