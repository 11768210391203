import { useEffect, useState, useRef } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,

} from 'react-bootstrap'
import Footer from './Footer';
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from 'react-router-dom'
import Calculator from './Calculator'
import Header from './Header'
import $ from 'jquery'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  

//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
//import $ from '../assets/js/bootstrap.bundle.min.js'


import '../assets/css/responsive.scss'
import { Helmet } from 'react-helmet'
const Sme = (onChange, value) => {

    const [sidebar, setSidebar] = useState(false)
    const navigate = useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
        if (window.scrollY > 120) {
            setNavClass('is-sticky');
        } else {
            setNavClass('');
        }
    };


    const showSidebar = () => {
        setSidebar(true)
    }
    const hideSidebar = () => {
        setSidebar(false)
    }
    const toggleSelect = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (selectedOption) => {
        onChange(selectedOption.value);
        setIsOpen(false);
    };
    const options = [
        { value: 1, label: 'Option 1' },
        { value: 2, label: 'Option 2' },
        { value: 3, label: 'Option 3' },
        { value: 4, label: 'Option 3' },
    ];
    const responsive = {

        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 1100 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1100, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 768 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 768, min: 576 },
            items: 2
        },
        smallMobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1
        }
    };
    useEffect(() => {
        $('.loader').fadeOut(1500);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };

        localStorage.getItem(sidebar)
        console.log(sidebar)

    });

    return (
 


    <body data-spy="scroll" data-offset="70">
        {/* Preloader */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
            <Header />
        {/* End Preloader */}

       

        {/* Banner */}
        <div id="home" class="one-banner-area" style={{marginTop:'60px'}}>
            <div class="banner-shape">
                <img src="/assets/images/sme1.png" alt="Banner"/>
                {/* <img src="/assets/images/sme2.png" alt="Banner"/> */}
            </div>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="banner-text">
                            <div class="banner-video">
                                <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank">
                                    <i class='bx bx-play' ></i>
                                </a>
                                <span>Into With Compnay</span>
                            </div>
                            <h1>Mortgage Is A Great For You</h1>
                            <p>Reliable Sustainable Provider</p>

                            <div class="banner-form">
                                <div class="banner-form-span">
                                    <span>Calculators</span>
                                </div>
                                <form>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Purchase price *"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Estimated monthly"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Down payment *"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Property ZIP code *"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <select class="form-control">
                                                    <option>Mortgage term length *</option>
                                                    <option>Another option</option>
                                                    <option>A option</option>
                                                    <option>Potato</option>
                                                </select>	
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="form-group">
                                                <input type="email" class="form-control" placeholder="Email address"/>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <button type="submit" class="btn banner-form-btn">
                                                Calculate money
                                            </button>
                                        </div>
                                        <div class="col-lg-6">
                                            <a class="banner-call" href="tel:5432123451">
                                                Call Us: 5432 123 451 (Sat - Thu)
                                            </a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-lg-6">
                        <div class="banner-slider owl-theme owl-carousel">
                            <div class="banner-inner banner-img-one">
                                <img src="/assets/img/home-one/banner-main-shape.png" alt="Banner"/>
                            </div>
                            <div class="banner-inner banner-img-two">
                                <img src="/assets/img/home-one/banner-main-shape.png" alt="Banner"/>
                            </div>
                            <div class="banner-inner banner-img-three">
                                <img src="/assets/img/home-one/banner-main-shape.png" alt="Banner"/>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        {/* End Banner */}

        {/* About */}
        <div id="about" class="one-about-area">
            <div class="container-fluid p-0">
                <div class="row m-0">
                    <div class="col-lg-6">
                        <div class="about-img">
                            <img src="/assets/images/sme3.png" alt="About"/>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="about-content">
                            <div class="one-section-title">
                                <span class="sub-title">ABOUT OUR COMPANY</span>
                                <h2>Why Should You Choose Us?</h2>
                                <p>Campaign dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam, </p>
                            </div>
                            <div class="about-tablist">
                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default active" id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">
                                            <i class='bx bx-bullseye' ></i>
                                            Our Mission
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default" id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">
                                            <i class='bx bx-show-alt'></i>
                                            Our Vision
                                        </a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-default" id="pills-contact-tab" data-bs-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">
                                            <i class='bx bx-pyramid'></i>
                                            Our Goal
                                        </a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                        <p>Compnay dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore meniam</p>
                                    </div>
                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less</p>
                                    </div>
                                    <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look</p>
                                    </div>
                                </div>
                            </div>
                            <div class="about-best">
                                <ul>
                                    <li>
                                        <h4>Best Mortgage Since 2002.</h4>
                                    </li>
                                    <li>
                                        <img src="/assets/img/home-one/about=sig.png" alt="About"/>
                                        <div class="sig-inner">
                                            <span>Donakd Zain</span>
                                            <p>CEO At Pento</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End About */}

        {/* Counter */}
        <div class="one-counter-area pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <h3>20</h3>
                            <p>YEARS OF EXPERIENCE</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <h3>98</h3>
                            <p>VOLUNTEERS PARTICIPATED</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <h3>56</h3>
                            <p>VETERAN HOMES COMPLETED</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-3">
                        <div class="counter-item">
                            <h3>90</h3>
                            <p>CUSTOMER WORLDWIDE</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Counter */}

        {/* Service */}
        <section id="service" class="one-service-area ptb-100">
            <div class="container">
                <div class="one-section-title">
                    <span class="sub-title">WHAT WE OFFER</span>
                    <h2>Our Core Service</h2>
                    <p>Experinces started sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore adipiscing elit sed do eiusr adipiscing elitatur  liqua</p>
                </div>
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <a class="nav-item nav-default active" id="nav-home-tab" data-bs-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">
                            <div class="service-item">
                                <i class='bx bxs-building-house'></i>
                                <span>Purchase New House</span>
                            </div>
                        </a>
                        <a class="nav-item nav-default" id="nav-profile-tab" data-bs-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">
                            <div class="service-item">
                                <i class='bx bxs-dollar-circle'></i>
                                <span>Apply For Loan</span>
                            </div>
                        </a>
                        <a class="nav-item nav-default" id="nav-contact-tab" data-bs-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                            <div class="service-item">
                                <i class='bx bxs-home-circle'></i>
                                <span>Refinancing Your Home</span>
                            </div>
                        </a>
                        <a class="nav-item nav-default" id="nav-x-tab" data-bs-toggle="tab" href="#nav-x" role="tab" aria-controls="nav-x" aria-selected="false">
                            <div class="service-item">
                                <i class='bx bxs-hand-up'></i>
                                <span>Capital Management</span>
                            </div>
                        </a>
                        <a class="nav-item nav-default" id="nav-y-tab" data-bs-toggle="tab" href="#nav-y" role="tab" aria-controls="nav-y" aria-selected="false">
                            <div class="service-item">
                                <i class='bx bxs-wrench'></i>
                                <span>Renovation House</span>
                            </div>
                        </a>
                        <a class="nav-item nav-default" id="nav-z-tab" data-bs-toggle="tab" href="#nav-z" role="tab" aria-controls="nav-z" aria-selected="false">
                            <div class="service-item">
                                <i class='bx bxs-user-circle'></i>
                                <span>Refinance Advisor</span>
                            </div>
                        </a>
                    </div>
                </nav>
                    
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/images/sme4.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Purchase New House</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/img/home-one/advisor2.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Apply For Loan</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#"  target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/img/home-one/advisor3.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Refinancing Your Home</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-x" role="tabpanel" aria-labelledby="nav-x-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/img/home-one/advisor4.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Capital Management</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-y" role="tabpanel" aria-labelledby="nav-y-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/img/home-one/advisor5.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Renovation House</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-z" role="tabpanel" aria-labelledby="nav-z-tab">
                        <div class="service-advisor">
                            <div class="row align-items-center">
                                <div class="col-lg-6">
                                    <div class="advisor-img">
                                        <img src="/assets/img/home-one/advisor6.jpg" alt="Advisor"/>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="advisor-content">
                                        <h2>Refinance Advisor</h2>
                                        <p>Our plan dolor sit amet conseetur diisci velit sed quiLorem ipsum dolor sit ame consectetur adipisicing elit</p>
                                        <ul>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Respect for all people
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Excellence in everything we do
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Truthfulness in our business
                                            </li>
                                            <li>
                                                <i class='bx bxs-chevrons-right'></i>
                                                Unquestionable integrity
                                            </li>
                                        </ul>
                                        <div class="advisor-link">
                                            <a href="#" target="_blank">
                                                READ MORE
                                            </a>
                                            <i class="bx bx-right-arrow-alt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Service */}

        {/* Help */}
        <section class="one-help-area ptb-100">
            <div class="help-img">
                <img src="/assets/images/sme5.png" alt="Help"/>
            </div>
            <div class="container">
                <div class="help-item">
                    <div class="one-section-title">
                        <span class="sub-title">BUYING A HOME</span>
                        <h2>We Are Here To Help You</h2>
                    </div>
                    <ul>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-brain'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Make it Your Dream</h3>
                                <p>When you own your own homet seeturlit sed dolor sit am</p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-home-alt'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Create A Place</h3>
                                <p>You can grow in your new homeit amet seeturlit sed dolor sit </p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-brain'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Be A Part Of Community</h3>
                                <p>Buying a home is an investmemet seeturlit sed dolo ameol</p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-home-alt'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Pay Principal</h3>
                                <p>Gain home equity by paying sit amet seeturlit sed dolor sit </p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-brain'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Get Tax Benefits</h3>
                                <p>Take advantage of tax benefitsit amet seeturlit sed dolor sit </p>
                            </div>
                        </li>
                        <li>
                            <div class="help-icon">
                                <i class='bx bx-home-alt'></i>
                            </div>
                            <div class="help-inner">
                                <h3>Build Credit</h3>
                                <p>Our plan dolor sit amet seeturlit sed dolor sit ameolor sit </p>
                            </div>
                        </li>
                    </ul>
                    <div class="advisor-link">
                        <a href="#contact">
                            GET STARTED
                        </a>
                        <i class="bx bx-right-arrow-alt"></i>
                    </div>
                </div>
            </div>
        </section>
        {/* End Help */}

        {/* Among */}
        
        {/* End Among */}

        {/* Quote */}
        <div class="one-quote-area">
            <div class="container">
                <div class="row m-0">
                    <div class="col-lg-6 p-0">
                        <div class="quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="#" target="_blank">
                                <i class='bx bxs-quote-alt-right'></i>
                                Get A Quote
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6 p-0">
                        <div class="quote-img">
                            <img src="/assets/img/home-one/quote.jpg" alt="Quote"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Quote */}

        {/* Search */}
        <div class="one-search-area ptb-100">
            <div class="container">
                <div class="search-wrap">
                    <div class="search-content">
                        <div class="one-section-title">
                            <span class="sub-title">WHY CHOOSE US</span>
                            <h2>Among Them We’re</h2>
                        </div>
                        <form>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                                <label class="form-check-label" for="exampleRadios1">
                                    Real Estate Agents
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                                <label class="form-check-label" for="exampleRadios2">
                                    Loan Officer
                                </label>
                            </div>
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="First Name"/>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Last Name"/>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <select class="form-control">
                                            <option>License State</option>
                                            <option>Another option</option>
                                            <option>A option</option>
                                            <option>Potato</option>
                                        </select>	
                                    </div>
                                </div>
                            </div>
                            <div class="search-link">
                                <button type="submit" class="btn">Search Your Result</button>
                            </div>
                        </form>
                    </div>
                    <div class="search-img">
                        <img src="/assets/images/sme9.png" alt="Search"/>
                    </div>
                </div>
            </div>
        </div>
        {/* End Search */}

        {/* Team */}
       
        {/* End Team */}

        {/* FAQ */}
        <div class="one-faq-area ptb-100">
            <div class="container">
                <div class="faq-content">
                    <ul class="accordion">
                        <li>
                            <a>How do you qualify for a loan?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>A Good Lender Will Clearly Explain Mortgage?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>Can You Get A Mortgage Without A Credit Score?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                        <li>
                            <a>How Much Home Can You Afford?</a>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Ut enim ad minim veniam</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="faq-img">
                <img src="/assets/images/sme6.png" alt="FAQ"/>
            </div>
        </div>
        {/* End FAQ */}

        {/* Testimonial */}
      
        {/* End Testimonial */}

        {/* Contact */}
        <div id="contact" class="one-contact-area">
            <div class="container-fluid p-0">
                <div class="row m-0">
                    <div class="col-lg-5 p-0">
                        <div class="contact-img">
                            <img src="/assets/images/sme7.jpg" alt="Conatact"/>
                        </div>
                    </div>
                    <div class="col-lg-7 p-0">
                        <div class="contact-content">
                            <div class="one-section-title">
                                <span class="sub-title">LET’S CONTACT</span>
                                <h2>Contact With Us</h2>
                            </div>
                            <form id="contactForm">
                                <div class="row m-0">
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                <i class='bx bx-user'></i>
                                            </label>
                                            <input type="text" name="name" id="name" class="form-control" required data-error="Please enter your name" placeholder="Name"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                <i class='bx bx-mail-send'></i>
                                            </label>
                                            <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your email" placeholder="Email"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                <i class='bx bx-phone-call'></i>
                                            </label>
                                            <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
            
                                    <div class="col-sm-6 col-lg-6">
                                        <div class="form-group">
                                            <label>
                                                <i class='bx bxs-edit-alt'></i>
                                            </label>
                                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject"/>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label>
                                                <i class='bx bx-message-square-detail'></i>
                                            </label>
                                            <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Message"></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                    </div>
        
                                    <div class="col-md-12 col-lg-12">
                                        <div class="row m-0">
                                            <div class="col-sm-4 col-lg-4 pl-0">
                                                <button type="submit" class="contact-btn btn">
                                                    <i class='bx bx-plus'></i>
                                                    Send Message
                                                </button>
                                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                                                <div class="clearfix"></div>
                                            </div>
                                            <div class="col-sm-8 col-lg-8">
                                                <div class="contact-emergency">
                                                    <i class='bx bx-phone-call'></i>
                                                    <span>Emergency Contact</span>
                                                    <a href="tel:+44554348554">+445 543 48554</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Contact */}

        {/* Blog */}
       
        {/* End Blog */}

     

        

        {/* dark version */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        <Footer />
        {/* dark version */}


        {/* Essential JS */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* Magnific Popup JS */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* Owl Carousel JS */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* Nice Select JS */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* Form Ajaxchimp JS */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* Form Validator JS */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* Contact JS */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* Mixitup JS */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* Custom JS */}
        <script src="/assets/js/custom.js"></script>
    </body>

      )
  }

export default Sme
