import { useEffect, useRef, useState } from "react";

import $ from "jquery";
import { BsWhatsapp } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
//   import OwlCarousel from 'react-owl-carousel';

//   import 'owl.carousel/dist/assets/owl.carousel.css';

//   import 'owl.carousel/dist/assets/owl.theme.default.css';
//import $ from '../assets/js/bootstrap.bundle.min.js'

import "../assets/css/responsive.scss";
const Team = (onChange, value) => {
  const [sidebar, setSidebar] = useState(false);
  const navigate = useNavigate();
  const [navClass, setNavClass] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef();
  const handleScroll = () => {
    if (window.scrollY > 120) {
      setNavClass("is-sticky");
    } else {
      setNavClass("");
    }
  };

  const showSidebar = () => {
    setSidebar(true);
  };
  const hideSidebar = () => {
    setSidebar(false);
  };
  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
    { value: 4, label: "Option 3" },
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2,
    },
    smallMobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
    },
  };
  useEffect(() => {
    $(".loader").fadeOut(1500);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

    localStorage.getItem(sidebar);
    console.log(sidebar);
  });

  return (
    <body data-spy="scroll" data-offset="70">
      {/* <!-- Preloader --> */}
      <div class="loader">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="spinner"></div>
          </div>
        </div>
      </div>
      {/* <!-- End Preloader --> */}

      {/* <!-- Start Main Top Header Area --> */}

      <Header />
      {/* <!-- End Main Navbar Area --> */}

      {/* <!-- Start Main Banner Area --> */}
      <div
        id="home"
        class="confidence-home-area"
        style={{ marginTop: "100px" }}
      >
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-12" style={{ textAlign: "center" }}>
              <div class="confidence-home-content">
                {/* <span class="sub-title" style={{fontSize: '25px'}}>Bandenia</span> */}
                <h1>MORTGAGE EXPERTS </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Main Banner Area -->
        
        <!-- StartOur Team Area --> */}
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12 mt-5 advisors">
            <h2> Our top mortgage advisors </h2>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "3" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/sabina.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Sabina </h4>
                <a
                  href="https://wa.me/+971542173349?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 54 217 3349{" "}
                  </h6>
                </a>
                <a href="mailto:Sabina@bcf.ae">
                  <h6>
                    <FiMail /> Sabina@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "2" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/ibrahim.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Ibrahim Shaikh </h4>
                <a
                  href="https://wa.me/+971565372034?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 56 537 2034{" "}
                  </h6>
                </a>
                <a href="mailto:Ibrahim@bcf.ae">
                  <h6>
                    <FiMail /> Ibrahim@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 p-3" style={{ order: "1" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/Wasim.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Wasim Shaikh </h4>
                <a
                  href="https://wa.me/+971564067781?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 56 406 7781{" "}
                  </h6>
                </a>
                <a href="mailto:wasim@bcf.ae">
                  <h6>
                    <FiMail /> wasim@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "4" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/kapil.jpg" alt="kapil" />
              </figure>
              <div class="green">
                <h4> Kapil Sewani </h4>
                <a
                  href="https://wa.me/+971524805639?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 52 480 5639{" "}
                  </h6>
                </a>
                <a href="mailto:Kapil@bcf.ae">
                  <h6>
                    <FiMail /> Kapil@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "5" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img
                  src="./assets/images/Abdul.jpg"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </figure>
              <div class="green">
                <h4> Abdul Khader Syed </h4>
                <a
                  href="https://wa.me/+971582496847?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 58 249 6847{" "}
                  </h6>
                </a>
                <a href="mailto:Abdul.khader@bcf.ae">
                  <h6>
                    <FiMail /> Abdul.khader@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 p-3" style={{ order: "6" }}>
            <div className="border-radius m-3 p-3">
              <figure class="image-height">
                <img src="./assets/images/maheen.jpg" alt="" />
              </figure>
              <div class="green">
                <h4> Maheen Taimur </h4>
                <a
                  href="https://wa.me/+971557120434?text="
                  target="_blank"
                  rel="noreferrer"
                >
                  <h6>
                    <BsWhatsapp /> +971 55 712 0434{" "}
                  </h6>
                </a>
                <a href="mailto:maheen@bcf.ae">
                  <h6>
                    <FiMail /> maheen@bcf.ae{" "}
                  </h6>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4 col-md-6 p-3">
                <div className="border-radius m-3 p-3">
                    <figure class="image-height">
                        <img src="./assets/images/sadaan.jpg" alt=""  />
                    </figure>
                    <div class="green">
                    <h4> Sayed Mohammed Sadaan </h4>
                    <a href="https://wa.me/+971551251768?text=" target="_blank"><h6><BsWhatsapp/> +971 55 125 1768 </h6></a>
                    <a href="mailto:sadaan@bcf.ae"><h6><FiMail/> sadaan@bcf.ae </h6></a>
                    </div>
                    </div>
                </div> */}
          {/* <div className="col-lg-4 col-md-6 p-3">
                <div className="border-radius m-3 p-3">
                    <figure class="image-height">
                        <img src="./assets/images/Shibli.jpg" alt=""  />
                    </figure>
                    <div class="green">
                    <h4> Syed Muhammad Shibli </h4>
                    <a href="https://wa.me/+971551312996?text=" target="_blank"><h6><BsWhatsapp/> +971 55 131 2996 </h6></a>
                    <a href="mailto:Shibli@bcf.ae"><h6><FiMail/> Shibli@bcf.ae </h6></a>
                    </div>
                    </div>
                </div> */}
        </div>
      </div>

      {/* <!-- Start Mortgage Quote Area --> */}
      <div class="mortgage-quote-area-with-full-width">
        <div class="container-fluid">
          <div class="row m-0">
            <div class="col-lg-6 col-md-6 p-0">
              <div class="mortgage-quote-content">
                <h3>Get Mortgage Quote</h3>
                <p>Compare the best mortgage rates across the whole market </p>
                <a href="https://user.mortgagebcf.com/" class="quote-btn">
                  Get Mortgage Quote
                </a>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 p-0">
              <div class="mortgage-quote-image"></div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </body>
  );
};

export default Team;
