
import { useEffect, useState , useRef} from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Balance = (onChange, value) => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef();
    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    onChange(selectedOption.value);
    setIsOpen(false);
  };
  const options = [
    {value: 1, label: 'Option 1'},
    {value: 2, label: 'Option 2'},
    {value: 3, label: 'Option 3'},
    {value: 4, label: 'Option 3'},
  ];
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    
    return (
   
    <body data-spy="scroll" data-offset="70">
        {/* <!-- Preloader --> */}
        <div class="loader">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="spinner"></div>
                </div>
            </div>
        </div>
        {/* <!-- End Preloader --> */}

        {/* <!-- Start Main Top Header Area --> */}
       
        <Header/>
        {/* <!-- End Main Navbar Area --> */}

        {/* <!-- Start Main Banner Area --> */}
        <div id="home" class="main-banner-area" style={{marginTop:"80px"}}>
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="main-banner-content">
                            <span class="sub-title">Reliable Sustainable Provider</span>
                            <h1>Balance Transfer</h1>
                            <div class="banner-popup align-items-center">
                                <a href="https://www.youtube.com/watch?v=-7NRB9V45n4" class="video-btn popup-youtube" target="_blank"><i class="bx bx-play"></i> <span>Intro With Company</span></a>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        {/* <!-- End Main Banner Area -->
        
        <!-- Start About Style Area --> */}
        <div id="about" class="about-style-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                   
                    <div class="col-lg-12 text-center col-md-12">
                        <div class="about-style-content">
                            {/* <span class="sub-title">About Our Company</span> */}
                            <h3>Balance Transfer</h3>
                            <p class="bold">
                                If you have an existing mortgage loan and not happy with rising rates on your mortgage then get in touch with our team so that we can propose better rates and options. At Bandenia Challenger Finance, we understand that transferring your mortgage can be a complex and time-consuming process, which is why we offer a range of services to help make the process easier and more streamlined for you.
                            </p>

                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End About Area -->

       
        <!-- Start Buying Home Area --> */}
        <div class="buying-home-area pb-100">
            <div class="container-fluid">
                <div class="row align-items-center justify-content-center">
                    

                    <div class="col-lg-12 col-md-12">
                        <div class="buying-home-content">
                            {/* <span class="sub-title text-center">Buying A Home</span> */}
                            <h3 class="text-center">We Are Here To Help You</h3>
                            <p class='text-center'>Here are some ways that we can help you with your mortgage balance transfer:</p>
                            <div class="row justify-content-center">
                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Providing expert advice :</h4>
                                        <p>
                                        Our team of mortgage experts can provide you with the best possible option and ensure you save on your monthly instalments as compared to what you are paying your existing lender. We will provide you with unbiased and professional advice on the best balance transfer options for your specific situation. We'll help you to understand the pros and cons of different products and lenders, and guide you through the application process from start to finish.
                                        </p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Access to a wide range of lenders: </h4>
                                        <p>We have tied up with all the leading Banks in UAE and can offer you the best solution as per your requirement. This means we can help you to find the best balance transfer product and lender to meet your needs, and offer exclusive deals and discounts.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Assistance with paperwork and documentation:  </h4>
                                        <p>We know that transferring your mortgage can involve a lot of paperwork and documentation. That's why we offer assistance with completing forms, gathering supporting documentation, and liaising with lenders to ensure a smooth and hassle-free application process.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Help with negotiations:  </h4>
                                        <p>If you're looking to negotiate the terms of your balance transfer, our team can help you to negotiate with lenders to secure the best possible deal. We'll work with you to understand your needs and priorities, and leverage our industry expertise to help you achieve your goals.</p>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-sm-6">
                                    <div class="buying-inner-box">
                                        <div class="icon">
                                            <i class='bx bx-check'></i>
                                        </div>
                                        <h4>Ongoing support:  </h4>
                                        <p>
                                        At Bandenia Challenger Finance, we believe that our relationship with you doesn't end once your balance transfer is complete. We offer ongoing support and advice to help you manage your mortgage and finances, and ensure that you're getting the best possible value from your mortgage product.
                                        </p>
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* {/* <!-- End Buying Home Area --> */}
        <div class="testimonials-style-area bg-ffffff pb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-5 col-md-5">
                        <div class="testimonials-style-image">
                            <img src="assets/images/testimonials-4.png" alt="image"/>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <div class="testimonials-style-content">
                            
                            <h3>Conclusion</h3>
                            <p>
                            Overall, we're here to help you navigate the complex and sometimes confusing world of mortgage balance transfers. Whether you're looking to secure a lower interest rate, access equity in your property, or improve your financial flexibility, we're committed to helping you achieve your goals and making the process as easy and stress-free as possible. Contact us today to learn more about how we can help you with your mortgage balance transfer.
                            </p>
                           
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonials-gradient-shape">
                <img src="assets/images/shape.png" alt="image"/>
            </div>
        </div>

        {/* <!-- Start Mortgage Quote Area --> */} 
        <div class="mortgage-quote-area-with-full-width">
            <div class="container-fluid">
                <div class="row m-0">
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-content">
                            <h3>Get Mortgage Quote</h3>
                            <p>Compare the best mortgage rates across the whole market  </p>
                            <a href="https://user.mortgagebcf.com/" class="quote-btn">Get Mortgage Quote</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 p-0">
                        <div class="mortgage-quote-image"></div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- End Mortgage Quote Area -->

        <!-- Start Why Choose Us Area --> */}
       

        
<Footer/>
        {/* <!-- dark version --> */}
        {/* <div class="dark-version">
            <label id="switch" class="switch">
                <input type="checkbox" onchange="toggleTheme()" id="slider"/>
                <span class="slider round"></span>
            </label>
        </div> */}
        {/* <!-- dark version --> */}

        {/* <!-- Essential JS --> */}
        <script src="/assets/js/jquery.min.js"></script>
        <script src="/assets/js/bootstrap.bundle.min.js"></script>
        {/* <!-- Magnific Popup JS --> */}
        <script src="/assets/js/jquery.magnific-popup.min.js"></script>
        {/* <!-- Owl Carousel JS --> */}
        <script src="/assets/js/owl.carousel.min.js"></script>
        {/* <!-- Nice Select JS --> */}
        <script src="/assets/js/jquery.nice-select.min.js"></script>
        {/* <!-- Form Ajaxchimp JS --> */}
		<script src="/assets/js/jquery.ajaxchimp.min.js"></script>
		{/* <!-- Form Validator JS --> */}
		<script src="/assets/js/form-validator.min.js"></script>
		{/* <!-- Contact JS --> */}
        <script src="/assets/js/contact-form-script.js"></script>
        {/* <!-- Mixitup JS --> */}
        <script src="/assets/js/jquery.mixitup.min.js"></script>
        {/* <!-- Custom JS --> */}
        <script src="/assets/js/custom.js"></script>
    </body>
        
    )
  }
  
  export default Balance
