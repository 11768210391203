import { useEffect, useState } from 'react'

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Form,
    InputGroup,
    Dropdown,
    
  } from 'react-bootstrap'
  import Footer from './Footer';
  import {GiHamburgerMenu } from "react-icons/gi";
  import { Link , useNavigate } from 'react-router-dom'
  import Calculator from './Calculator'
  import Header from './Header'
  import $ from 'jquery'
  import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
//   import OwlCarousel from 'react-owl-carousel';  

//   import 'owl.carousel/dist/assets/owl.carousel.css';  
 
//   import 'owl.carousel/dist/assets/owl.theme.default.css'; 
  //import $ from '../assets/js/bootstrap.bundle.min.js'
 
  
  import '../assets/css/responsive.scss'
  import { Helmet } from 'react-helmet'
  const Findloan = () => {
   
    const[sidebar, setSidebar]=useState(false)
    const navigate=useNavigate();
    const [navClass, setNavClass] = useState('');

    const handleScroll = () => {
      if (window.scrollY > 120) {
        setNavClass('is-sticky');
      } else {
        setNavClass('');
      }
    };
  
   
    const showSidebar=()=>{
           setSidebar(true)
    }
    const hideSidebar=()=>{
        setSidebar(false)
 }
 const responsive = {
  
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1100 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 1100, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 576 },
      items: 2
    },
    smallMobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1
      }
  };
 useEffect(() => {
    $('.loader').fadeOut(1500);
    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      localStorage.getItem(sidebar)
      console.log(sidebar)
    
    });
    

   
  

    return (
<div class="find-own-loan-area pt-100 pb-70">
            <div class="container">
                <div class="main-section-title">
                    {/* <span class="sub-title">Categories Of Loan</span> */}
                    <h2>Mortgage BCF-  All in One Platform</h2>
                </div>

                <div class="find-own-loan-list-tab text-center">
                  
                  
                  {/* autoPlay={true} */}
                <Carousel  responsive={responsive} infinite={true} autoPlay={true}>
                <a href="/mortgage">
                    <div class="find-own-loan-card relative" >
                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/home.jpg" alt="image"/>
                                      </div>
                                     </figure>
                                    <div class="loan-content">
                                        <span class="tag">Home Loan</span>
                                        {/* <h3> Home Loan <br/> </h3> */}
                                        <p class="black">Are you're looking for <br/> a mortgage?</p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/mortgage" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    <a href="/loan">
                                    <div class="find-own-loan-card relative"  >
                                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/property.jpg" alt="image"/>
                                    </div>
                                    </figure>
                                    <div class="loan-content">
                                    <span class="tag">Loan Against Property (cash out) </span>
                                        {/* <h3> Loan Against Property (cash out) </h3> */}
                                        <p class="black">Do you own a property, you can leverage its value to obtain a loan</p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/loan" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    <a href="/resident">
                                    <div class="find-own-loan-card relative" >
                                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/nonresident.jpg" alt="image"/>
                                    </div>
                                    </figure>
                                    <div class="loan-content">
                                    <span class="tag"> Non-Resident Loan</span>
                                        {/* <h3>Non-Resident Loan <br/></h3> */}
                                        <p class="black">Are you a non-resident looking to purchase a property in the UAE</p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/resident" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    <a href="/balance">
                                    <div class="find-own-loan-card relative" >
                                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/Balance.jpg" alt="image"/>
                                    </div>
                                    </figure>
                                    <div class="loan-content">
                                    <span class="tag">Balance transfers</span>
                                        {/* <h3> Balance transfer</h3> */}
                                        <p class="black">Balance transfer can help you pay off your debts </p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/balance" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    {/* <div class="find-own-loan-card relative" >
                                    <div class="loan-image">
                                        <img src="/assets/images/image5.png" alt="image"/>
                                    </div>
                                    <div class="loan-content">
                                        <span class="tag">Top up</span>
                                        
                                        <p class="black">Migration do amet contur diisci velit suia nonnua</p>
                                    </div>
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/mortgage" class="calculate">Read More </a>
                                    </div>
                                    </div> */}
                                    <a href="/commercial">
                                    <div class="find-own-loan-card relative"  >
                                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/commercial.jpg" alt="image"/>
                                    </div>
                                    </figure>
                                    <div class="loan-content">
                                    <span class="tag">	Commercial Mortgage </span>
                                        {/* <h3>Commercial loan </h3> */}
                                        <p class="black">Looking to purchase a new commercial property</p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/commercial" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    <a href="/warehouse">
                                    <div class="find-own-loan-card relative" >
                                    <figure class="image-height">
                                    <div class="loan-image">
                                        <img src="/assets/images/warehouse.jpg" alt="image"/>
                                    </div>
                                    </figure>
                                    <div class="loan-content">
                                    <span class="tag">Warehouse & Building Finance</span>
                                        {/* <h3>Warehouse & Building Finance</h3> */}
                                        <p class="black">Looking to purchase a warehouse or building in the UAE</p>
                                    </div>
                                    <div class="bottom-float">
                                    <div class="option-item" style={{marginTop:'10px', alignItems:'center',}}>
                                    <a href="/warehouse" class="calculate">Read More </a>
                                    </div>
                                    </div>
                                    </div>
                                    </a>
                                    
                                </Carousel>
                </div>
            </div>
        </div>
        
    )
}
    export default Findloan